<template>
  <div class="update">
    <div>
      <h1>Opdater tekster</h1>

      <div class="update__text">
        <h2>Disclaimer</h2>

        <div class="update__text__chunks">
          <div>
            <strong>Nuværende tekst:</strong>
            <div class="output">
              {{ currentDisclaimerText }}
            </div>
          </div>
          <div>
            <strong>Ny tekst:</strong>
            <textarea class="input" v-model="newDisclaimerText"></textarea>
          </div>
        </div>
        <div class="buttons single">
          <button type="button" class="button" @click="updateDisclaimerContent">
            Opdater
          </button>
        </div>
      </div>

      <div class="update__text">
        <h2>Hjælpeside</h2>

        <div class="update__text__chunks">
          <div>
            <strong>Nuværende tekst:</strong>
            <div class="output" v-html="currentHelpText"></div>
          </div>
          <div>
            <strong>Ny tekst:</strong>
            <textarea class="input" v-model="newHelpText"></textarea>
          </div>
        </div>
        <div class="buttons single">
          <button type="button" class="button" @click="updateHelpContent">
            Opdater
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
export default {
  name: "UpdateContent",

  props: {
    msg: String,
  },
  data: function() {
    return {
      disclaimerUID: null,
      currentDisclaimerText: null,
      newDisclaimerText: null,
      helpUID: null,
      currentHelpText: null,
      newHelpText: null,
    };
  },
  methods: {
    getContent() {
      this.getDisclaimerContent();
      this.getHelpContent();
    },
    getDisclaimerContent() {
      const vm = this;
      var disclaimer = firebase.database().ref("/content/disclaimer");
      disclaimer.on("value", (snapshot) => {
        const data = snapshot.val();

        if (Object.values(data).length) {
          vm.disclaimerUID = Object.keys(data)[0];
          vm.currentDisclaimerText = Object.values(data)[0].text;
        }
        vm.newDisclaimerText = vm.currentDisclaimerText;
      });
    },
    updateDisclaimerContent() {
      var postData = this.payload(this.newDisclaimerText);
      var updates = {};
      updates["/content/disclaimer/" + this.disclaimerUID] = postData;

      this.update(updates);
    },
    getHelpContent() {
      const vm = this;
      var disclaimer = firebase.database().ref("/content/help");
      disclaimer.on("value", (snapshot) => {
        const data = snapshot.val();

        if (Object.values(data).length) {
          vm.helpUID = Object.keys(data)[0];
          vm.currentHelpText = Object.values(data)[0].text;
        }
        vm.newHelpText = vm.currentHelpText;
      });
    },
    updateHelpContent() {
      var postData = this.payload(this.newHelpText);

      if (this.helpUID) {
        var updates = {};
        updates["/content/help/" + this.helpUID] = postData;
        this.update(updates);
      } else {
        var help = firebase.database().ref("/content/help/");
        this.helpUID = help.push(postData).key;
      }
    },
    payload(newText) {
      var postData = {
        text: newText,
      };
      return postData;
    },
    update(updates) {
      firebase
        .database()
        .ref()
        .update(updates);
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style scoped lang="scss">
.update {
  width: 80%;
  margin-bottom: 6rem;
  &__text {
    margin: 1rem 0;
    padding: 1rem;
    border: solid 1px #dbdbdb;
    border-radius: 4px;
    &__chunks {
      margin: 1rem 0;
      display: flex;
      > div {
        width: 50%;
      }
      textarea {
        height: 300px;
      }
      .output {
        padding: 1rem 1rem 1rem 0;
      }
    }
  }
}
</style>
