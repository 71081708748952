<template>
  <div class="container content">
    <UpdateContent />
  </div>
</template>

<script>
// @ is an alias to /src
import UpdateContent from "@/components/UpdateContent.vue";

export default {
  name: "Content",
  components: {
    UpdateContent,
  },
};
</script>
